/* eslint-disable no-bitwise */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useAccounts } from '@/features/accounts/useAccounts';
import { PageLayout } from '@/components/page-layout';
import { routes } from '@/webapi/routes';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { CardButton, MixedCardLayout } from '@/components/card-button';
import { AccountsFilter } from '@/features/accounts/filter-accounts';
import {
  MyAccountsContext,
  newMyAccountsContext,
} from '@/features/accounts/context';
import { Pagination } from '@/components/pagination';
import { ExperienceSpinner } from '@/components/experience-spinner';
import { breakpoints } from '@/components/responsive';

export const AccountsPage: FC = () => {
  const ctx = newMyAccountsContext();
  const { page, limit, sort, searchText, setPage } = ctx;
  const req = {
    page,
    limit,
    sortDirection: sort.direction,
    sortOption: sort.option,
    searchText,
  };
  const { accounts, onSelect, loading, total } = useAccounts(req);

  const getHomepageScreenshot = (storeAlias: string): string => {
    let prefix = `https://sdk.loomi-stg.xyz`;
    if (routes.getEnv() === 2) {
      prefix = `https://sdk.loomi-prod.xyz`;
    }
    return `${prefix}/media/snapshots/home_${storeAlias}.png`;
  };

  useEffect(() => {
    if (loading) showLoader();
    else hideLoader();
  }, [loading]);

  const onPageChanged = (page: number) => {
    setPage(page - 1);
  };
  return (
    <MyAccountsContext.Provider value={ctx}>
      <PageLayout
        hideBackButton={false}
        heading="Connected Stores"
        description="Click on the store you want to manage"
      >
        <Controls>
          <AccountsFilter />
        </Controls>
        {!loading && accounts.length === 0 && (
          <V2Wrapper>
            <V2Text>
              <>
                There are no results matching your criteria
                <br />
                You can refine your search criteria
              </>
            </V2Text>
          </V2Wrapper>
        )}
        {loading ? (
          <SpinnerWrapper>
            <ExperienceSpinner
              size={4}
              thickness={0.5}
              color="rgba(205,210,215)"
            />
          </SpinnerWrapper>
        ) : (
          <Grid loading={loading}>
            {accounts?.map((store) => (
              <UninstalledWrapper isUninstalled={store.isUninstalled}>
                <CardButton
                  key={store.storeAlias}
                  canBeSelected
                  onClicked={() => onSelect(store.storeAlias)}
                >
                  <MixedCardLayout
                    primaryColor="black"
                    accentColor="#758390"
                    content={
                      <Image
                        src={getHomepageScreenshot(store.storeAlias)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = `https://sdk.loomi-prod.xyz/VSLY/not_found.png`;
                        }}
                        alt={store.storeAlias}
                      />
                    }
                  >
                    {formatALias(store.storeAlias)}
                    <span>{toStoreDomain(store.storeAlias)}</span>
                    <ExperiencesWrapper>
                      <ExperienceData>
                        {store.isUninstalled
                          ? `Draft (${store.draftExperiences || 0})`
                          : `Live (${store.runningExperiences || 0})`}
                      </ExperienceData>
                      <ExperienceData>
                        {store.isUninstalled
                          ? `Paused (${store.pausedExperiences || 0})`
                          : `Draft (${store.draftExperiences || 0})`}
                      </ExperienceData>
                      <ExperienceData>
                        {store.isUninstalled
                          ? `Archived (${store.arhivedExperiences || 0})`
                          : `A/B (${store.abTestsExperiences || 0})`}
                      </ExperienceData>
                    </ExperiencesWrapper>
                    {store.isAppEmbedEnabled !== true && (
                      <AppEmbedStatus>
                        APP EMBED DISABLED FOR CLIENT
                      </AppEmbedStatus>
                    )}
                  </MixedCardLayout>
                </CardButton>
              </UninstalledWrapper>
            ))}
          </Grid>
        )}
        <Pagination
          className="only-desktop"
          value={page + 1}
          pageCount={total / limit || 0}
          pageSize={limit}
          total={total || 0}
          loading={loading}
          onPageChanged={onPageChanged}
          label="stores"
        />
        <Pagination
          windowSize={5}
          className="only-mobile"
          value={page + 1}
          pageCount={total / limit || 0}
          pageSize={limit}
          total={total || 0}
          loading={loading}
          onPageChanged={onPageChanged}
          label="stores"
        />
      </PageLayout>
    </MyAccountsContext.Provider>
  );
};

const Grid = styled.div`
  min-width: 100rem;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.5rem;
  margin-bottom: 4rem;

  filter: ${(p: any) => (p?.loading ? `grayscale(90%)` : `none`)};
  opacity: ${(p: any) => (p?.loading ? `0.5` : `1`)};
  pointer-events: ${(p: any) => (p?.loading ? `none` : `auto`)};

  transition: opacity 0.2s ease-in, filter 0.2s ease-in;

  ${breakpoints.down(`md`)} {
    min-width: unset;
    grid-template-columns: 1fr;
  }
`;

const Image = styled.img`
  background: #e8e8e8;
  max-height: 17rem;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 1rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.08);
`;

function toStoreDomain(storeAlias: string): string {
  const domain = `${storeAlias
    ?.replace(`_`, `-`)
    ?.toLocaleLowerCase()}.myshopify.com`;
  const maxLen = 35;
  if (domain.length > maxLen) {
    return `${domain.substring(0, maxLen - 3)}...`;
  }
  return domain;
}

function formatALias(storeAlias) {
  if (storeAlias === `DOLLAR_SHAVE_CLUB_STORE`) {
    return <span>DSC US</span>;
  }
  const alias = storeAlias?.replaceAll(`_`, ` `)?.substring(0, 20);
  if (alias.length > 20) {
    return <span>{`${alias.substring(0, 17)}...`}</span>;
  }
  return <span>{alias}</span>;
}

const Controls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const SpinnerWrapper = styled.div`
  min-height: 50rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const V2Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;
  justify-items: center;
  grid-gap: 4rem;
  padding: 8rem 0;
`;

const V2Text = styled.span`
  color: black;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 3.5rem;
`;

const ExperiencesWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: start;
`;

const ExperienceData = styled.span`
  color: #aeb5be;
  font-family: Inter, serif;
  font-weight: 600;
  font-size: 1.2rem;
`;

const UninstalledWrapper = styled.div`
  max-height: 220px;
  opacity: ${(p: { isUninstalled: boolean }) => (p?.isUninstalled ? 0.55 : 1)};
  filter: grayscale(
    ${(p: { isUninstalled: boolean }) => (p?.isUninstalled ? `100%` : `0`)}
  );

  ${breakpoints.down(`md`)} {
    width: 95%;
    margin: 0 auto;
  }
`;

const AppEmbedStatus = styled.span`
  color: rgb(237, 107, 107) !important;
  font-family: Inter, serif !important;
  font-weight: 700 !important;
  font-size: 1.2rem !important;
  line-height: 3.5rem !important;

  ${breakpoints.down(`md`)} {
    font-size: 1rem !important;
  }
`;
